<template>
    <div class="">
      <div class="o-section">
          <div class="js-entry o-entry c-flipbook-entry">
            <flipbook class="flipbook" :style="backgroundStyle" :pages="pageUrls" v-slot="flipbook" :gloss="0.2" :centering="false" :zooms="[1]" :startPage="flipPage" @flip-left-end="onPageChange" @flip-right-end="onPageChange">
              <a v-if="flipbook.canFlipLeft" :class="{'o-flipbook__prev_next': true, 'o-flipbook__previous-page': true, 'is-hidden': $xp.navigation.isNavHidden}" @click.prevent="flipbook.flipLeft" >
                <span class="xp xp-chevron-left xp--large link"></span>
              </a>
              <a v-if="flipbook.canFlipRight" :class="{'o-flipbook__prev_next': true, 'o-flipbook__next-page': true, 'is-hidden': $xp.navigation.isNavHidden}" @click.prevent="flipbook.flipRight" >
                <span class="xp xp-chevron-right xp--large link"></span>
              </a>
            </flipbook>
          </div>
        </div>
        <audio-player
                v-if="$xp.navigation.isAudioSynced"
                :source="$xp.settings.baseUrl + 'xpedeo/' + pageData.Audio.Url"
                :binaryId="pageData.Audio.BinaryID"
                :pageId="pageData.ID"
                :audioSync="pageData.AudioSync"
                :autoPlay="$xp.settings.autoPlayPageAudio"
                :lipSyncUrl="(pageData.Class === 'XpVideoSync') ? pageData.lipSyncUrl : null"></audio-player>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'
import Flipbook from 'flipbook-vue'

export default {
  mixins: [pageMixin],
  data () {
    return {
      pageId: 0,
      flipPage: 1,
      singlePageMode: false,
      $pageContainer: null,
      backgroundStyle: ''
    }
  },
  mounted () {
    this.pageId = this.pageData.ID
    const historyPage = this.$xp.history.historyParams.flipPage
    if (historyPage) {
      this.flipPage = historyPage
      console.log('Flipbook: set flipPage to ', historyPage)
    }

    this.$pageContainer = document.querySelector('.flipbook .container > div:last-of-type')
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          console.log('A child node has been added or removed.')
          this.detectSinglePageMode()
        }
      }
    })
    console.log('watchNode:', this.$pageContainer)
    observer.observe(this.$pageContainer, { childList: true })
    // observer.disconnect()
  },
  computed: {
    pageUrls () {
      const urls = []
      this.pageData.FlipbookImages.forEach((binary) => {
        let url = null
        if (binary) {
          let requiredImageWidth = this.$xp.content.getRequiredImageWidth(binary)
          if (requiredImageWidth !== binary.Width) {
            requiredImageWidth = Math.floor(requiredImageWidth / 2)
          }
          url = this.$xp.content.getImageUrl(binary, requiredImageWidth)
        }
        urls.push(url)
      })
      return urls
    }
  },
  methods: {
    detectSinglePageMode () {
      console.log('img.page:', this.$pageContainer.querySelectorAll('img.page'))
      const firstChild = this.$pageContainer.children[0]
      console.log('child:', firstChild, firstChild.clientWidth, ', container:', this.$pageContainer, this.$pageContainer.clientWidth)
      this.singlePageMode = this.$pageContainer.querySelectorAll('img.page').length < 2 && (firstChild.clientWidth - 1) * 2 > this.$pageContainer.clientWidth

      console.log('children:', this.$pageContainer.children)
      if (!this.singlePageMode && this.pageData.FlipbookBgImage) {
        const binary = this.pageData.FlipbookBgImage
        const requiredImageWidth = this.$xp.content.getRequiredImageWidth(binary)
        const url = this.$xp.content.getImageUrl(binary, requiredImageWidth)
        this.backgroundStyle = 'background-image:url(' + url + ')'
      } else {
        this.backgroundStyle = ''
      }
    },
    onPageChange (pageNo) {
      console.log('Flipbook: onPageChange(', pageNo, ')')
      this.flipPage = pageNo
      this.$xp.history.setHistoryParam(this.pageId, 'flipPage', pageNo)
    }
  },
  components: {
    Flipbook
  }
}
</script>

<style>
  .flipbook {
    background-repeat: no-repeat;
    background-size: auto 100vh;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    /* padding wird berechnet aus (höhe des hintergundbildes - höhe des buchblocks) / 2 (100% - 92,963% = 7,037% / 2 = 3,5185%) */
    padding-bottom: 3.5185vh;
    padding-top: 3.5185vh;
    width: 100vw;
  }
  .flipbook.portrait {
    /* bei hochformat: hintergrundbild ausblenden */
    background-image: none;
  }
  .o-flipbook__previous-page {
    left: 0;
  }
  .o-flipbook__next-page {
    right: 0;
  }
  .o-flipbook__prev_next {
    background-color: transparent;
    height: 3.5rem;
    position: absolute;
    text-align: center;
    top: calc(50% - 1.75rem);
    width: 3.5rem;
  }
  .o-flipbook__prev_next.is-hidden {
    display: none;
  }
  @media (min-width: 768px) {
    .flipbook {
      /* hier ist das padding so gross wie die kopf-/fusszeile, die höhe des background-images habe ich empirisch ermittelt ... vielleicht fällt jemandem eine mathematische lösung ein*/
      background-size: auto calc(106vh - 7rem - 4px);
      padding-bottom: calc(3.5rem + 2px);
      padding-top: calc(3.5rem + 2px);
    }
  }
</style>
